import ePub from 'epubjs'
import dataServices from '../../services/data';


function nextPos(bookId, rendition) {
  rendition.next().then(() => {

    rendition.on('relocated', (location) => {
      console.log("start " + location.start.cfi);
      console.log("end " + location.end.cfi);
    });

    console.log("Rendition nextPos");
    console.log(rendition);
    //console.log("in promise next :" + " " + JSON.stringify(rendition.currentLocation()));

  });
  //console.log("next:" + rendition.currentLocation().end.cfi);
  dataServices.saveUserPositions(bookId, "epub", rendition.currentLocation().end.cfi, 0.0)

}

function prevPos(bookId, rendition) {
  rendition.prev();
  //console.log("prev:" + rendition.currentLocation().start.cfi);
  console.log("Rendition prevPos");
    console.log(rendition);
  dataServices.saveUserPositions(bookId, "epub", rendition.currentLocation().start.cfi, 0.0)
}

export default {
  name: 'BookEpubReader',

  components: {
  },
  props: ["url", "bookID", "book"],
  data() {
    return {
      name: '', //change which pdf file loads
      spinner: false
    }
  },
  beforeMount() {
    this.$store.dispatch('setSpinner', true);
  },
  async mounted() {
    try {
      this.name = this.$root.$route.params.url;
      if (this.$root.$route.params.url) {
        this.name = this.$root.$route.params.url;
        localStorage['bookReaderUrl'] = this.$root.$route.params.url;
      } else {
        var bookReaderUrl = localStorage['bookReaderUrl'];
        if (bookReaderUrl) this.name = bookReaderUrl;
      }

      // var params = URLSearchParams && new URLSearchParams(document.location.search.substring(1));
      var currentSectionIndex = this.$root.$route.params.loc ? this.$root.$route.params.loc : undefined;
      var currentBookId = this.$root.$route.params.book ? this.$root.$route.params.book : undefined;
      // Load the opf
      const bookOptions = {
        openAs: 'epub',
        requestHeaders: { 'Content-type': 'application/epub+zip', 'Access-Control-Allow-Origin': '*' },
        requestCredentials: true
      };
      var book = ePub(this.name, bookOptions);
      var rendition = book.renderTo("viewer", {
        width: "100%",
        height: "100%",
        spread: "always"
      });

      rendition.display(currentSectionIndex)
        .then(() => console.log("rendition displayed"));

      book.ready.then(() => {
        console.log("book ready")
        var next = document.getElementById("next");

        next.addEventListener("click", function (e) {
          book.package.metadata.direction === "rtl" ? prevPos(currentBookId, rendition) : nextPos(currentBookId, rendition);
          e.preventDefault();
        }, false);

        var prev = document.getElementById("prev");
        prev.addEventListener("click", function (e) {
          book.package.metadata.direction === "rtl" ? nextPos(currentBookId, rendition) : prevPos(currentBookId, rendition);
          e.preventDefault();
        }, false);

        var keyListener = function (e) {

          // Left Key
          if ((e.keyCode || e.which) == 37) {
            book.package.metadata.direction === "rtl" ? nextPos(currentBookId, rendition) : prevPos(currentBookId, rendition);
          }

          // Right Key
          if ((e.keyCode || e.which) == 39) {
            book.package.metadata.direction === "rtl" ? prevPos(currentBookId, rendition) : nextPos(currentBookId, rendition);
          }

        };

        rendition.on("keyup", keyListener);
        document.addEventListener("keyup", keyListener, false);

      });

      //   var title = document.getElementById("title");
      rendition.on("relocated", function (location) {
        console.log("relocated next:" + location.end.cfi);
        console.log("relocated prev:" + location.start.cfi);
        console.log("relocated location:" + JSON.stringify(rendition.currentLocation()));

        const progress = book.locations.percentageFromCfi(location.start.cfi);
        console.log('Progress:', progress); // The % of how far along in the book you are
        //  console.log('Current Page:', book.locations.locationFromCfi(locations.start.cfi));
        //  console.log('Total Pages:', book.locations.total);

        var next = book.package.metadata.direction === "rtl" ? document.getElementById("prev") : document.getElementById("next");
        var prev = book.package.metadata.direction === "rtl" ? document.getElementById("next") : document.getElementById("prev");

        if (location.atEnd) {
          next.style.visibility = "hidden";
        } else {
          next.style.visibility = "visible";
        }

        if (location.atStart) {
          prev.style.visibility = "hidden";
        } else {
          prev.style.visibility = "visible";
        }

      });

      rendition.on("rendered", function (section) {
        var current = book.navigation && book.navigation.get(section.href);

        if (current) {
          var $select = document.getElementById("toc");
          var $selected = $select.querySelector("option[selected]");
          if ($selected) {
            $selected.removeAttribute("selected");
          }

          var $options = $select.querySelectorAll("option");
          for (var i = 0; i < $options.length; ++i) {
            let selected = $options[i].getAttribute("ref") === current.href;
            if (selected) {
              $options[i].setAttribute("selected", "");
            }
          }
        }

      });

      rendition.on("layout", function (layout) {
        let viewer = document.getElementById("viewer");

        if (layout.spread) {
          viewer.classList.remove('single');
        } else {
          viewer.classList.add('single');
        }
      });

      window.addEventListener("unload", function () {
        console.log("unloading");
        this.book.destroy();
      });

      book.loaded.navigation.then(function (toc) {
        var $select = document.getElementById("toc"),
          docfrag = document.createDocumentFragment();

        toc.forEach(function (chapter) {
          var option = document.createElement("option");
          option.textContent = chapter.label;
          option.setAttribute("ref", chapter.href);

          docfrag.appendChild(option);
        });

        $select.appendChild(docfrag);

        $select.onchange = function () {
          var index = $select.selectedIndex,
            url = $select.options[index].getAttribute("ref");
          rendition.display(url);
          return false;
        };

      });
    } catch (error) {
      console.log(error);
      alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
    } finally {
      this.$store.dispatch('setSpinner', false);
    }
  },
  created() {

  },
  methods: {

  }
}